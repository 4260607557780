/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.input {
  border-radius: 0%;
}

.react-datetimerange-picker {
  display: inline-flex;
  position: relative;
}

body::-webkit-scrollbar {
  display: none;
}

.react-datetimerange-picker,
.react-datetimerange-picker *,
.react-datetimerange-picker *:before,
.react-datetimerange-picker *:after {
}
.react-datetimerange-picker--disabled {
  background-color: #ea580c;
  color: #ea580c;
}
.react-datetimerange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
}
.react-datetimerange-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  display: none;
  visibility: hidden;
}
.react-datetimerange-picker__inputGroup__divider {
  padding: 1px 0;
  visibility: hidden;
}
.react-datetimerange-picker__inputGroup__input {
  max-width: 0.14em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-datetimerange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetimerange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datetimerange-picker__inputGroup__input:invalid {
  background: rgba(151, 1, 1, 0.1);
}
.react-datetimerange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-datetimerange-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-datetimerange-picker__button {
  background: transparent;
  padding: 4px 6px;
}
.react-datetimerange-picker__button:enabled {
  cursor: pointer;
}
.react-datetimerange-picker__button:enabled:hover
  .react-datetimerange-picker__button__icon,
.react-datetimerange-picker__button:enabled:focus
  .react-datetimerange-picker__button__icon {
  stroke: #0078d7;
}
.react-datetimerange-picker__button:disabled
  .react-datetimerange-picker__button__icon
  .react-datetimerange-picker__button
  svg {
  display: inherit;
}

.react-datetimerange-picker__range-divider {
  display: none;
  visibility: none !important;
}

.react-datetimerange-picker__clear-button {
  display: none;
}

.react-datetimerange-picker__calendar,
.react-datetimerange-picker__clock {
  z-index: 1;
}
.react-datetimerange-picker__calendar--closed,
.react-datetimerange-picker__clock--closed {
  display: none;
}
.react-datetimerange-picker__calendar {
  width: 350px;
  max-width: 100vw;
}
.react-datetimerange-picker__calendar .react-calendar {
  border-width: thin;
}
.react-datetimerange-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
}

.react-calendar {
  position: fixed;
  top: 50px;
  right: 0;
  inset: 0;
  width: 100%;
  max-width: 400px;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #ad8b19;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  padding: 10px 10px 10px 10px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 100%;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #81ff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #ea580c;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #ea580c;
}
.react-calendar__tile--active {
  background: #ea580c;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ea580c;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #ffedd5;
}

/* toggle button */

.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 41px;
}
.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(241, 40, 40);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.slideBtnTg:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: white;
}
input:checked + .slideBtnTg {
  background-color: #1d1e1f;
}
input:focus + .slideBtnTg {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slideBtnTg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slideBtnTg.round {
  border-radius: 34px;
}
.slideBtnTg.round:before {
  border-radius: 50%;
}

.react-datetimerange-picker__calendar-button__icon {
  color: #ea580c !important;
}
